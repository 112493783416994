import { CERTIFICATES } from './gemstones'

export const CERTIFICATE_MAP = {
  [CERTIFICATES.EGL]: 'EGL',
  [CERTIFICATES.GCAL]: 'GCAL',
  [CERTIFICATES.GIA]: 'GIA',
  [CERTIFICATES.IGI]: 'IGI',
  [CERTIFICATES.HRD]: 'HRD',
  [CERTIFICATES.DF]: 'DF',
}
